/*
 * 업무구분: 신탁>방문/내방선택
 * 화 면 명: MSPTS001M
 * 화면설명: 방문/내방선택
 * 작 성 일: 2023.04.07
 * 작 성 자: 송진의
 */
<template>
  <ur-page-container class="fts" :show-title="false" title="방문/내방 선택" :topButton="false">
    <header class="fts-header">
      <ur-box-container alignV="center" direction="row" class="fts-flex-sb">
        <nav class="btn-content">
          <mo-icon-button class="fts-main-ts" :borderless="true">홈으로</mo-icon-button>
        </nav>
      </ur-box-container>
    </header>

    <!-- .fts-main -->
    <main class="fts-main">
      <ur-box-container direction="row">
        <div class="wrap-fts-worktype">
          <mo-button class="visit" @click="fn_SelVstType('vst')">
            <div class="ico"></div>
            <span>방문</span>
          </mo-button>
          <mo-button class="comein" @click="fn_SelVstType('vss')">
            <div class="ico"></div>
            <span>내방</span>
          </mo-button>          
        </div>
      </ur-box-container>
    </main>
    <!-- //.fts-main -->

    <!-- "방문" 선택 시 노출되는 방문판매 동의 확인 팝업 -->
    <mo-modal class="fts-modal small pop-visitAgree" ref="vstAgr" title="방문판매 동의 확인">
      <template>
        <div class="wrap-modalcontents">
          <div class="column align-center">
            <h2>방문판매 동의 확인</h2>
            <span class="mt20 lh14">
              방문판매 확인 녹취를 진행합니다.<br/>
              고객 동의 여부를 확인해 주세요.
            </span>
            <mo-badge class="badge-rec-pop mt20" text="녹취 중" />
          </div>
        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="fn_SetVstAgr(false)">미동의</mo-button>
          <mo-button size="large" @click="fn_SetVstAgr(true)" primary>동의</mo-button>
        </div>
      </template>
    </mo-modal>

    <!-- popup500 -->
    <msp-fs-500p
      ref="popup500"
      @fs-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup500Obj"
    ></msp-fs-500p>

    <!-- popup504 -->
    <msp-fs-504p
      ref="popup504"
      @fs-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup504Obj"
    ></msp-fs-504p>

  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Msg from '@/systems/webkit/msg/msg'
import MSPFS500P from '~/src/ui/fs/MSPFS500P'  // 신분증진위 확인
import MSPFS504P from '~/src/ui/fs/MSPFS504P'  // 서류등록
import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import FSFileUtil from '@/ui/fs/comm/FSFileUtil'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPTS001M",
  screenId: "MSPTS001M",
  components: {
    'msp-fs-500p': MSPFS500P,
    'msp-fs-504p': MSPFS504P,
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ExitApp)
    if(this.$route.params.jobTyp){
      this.lv_jobTyp = this.$route.params.jobTyp
    }
  },
  mounted() {
    this.$bizUtil.insSrnLog("MSPTS001M");
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // 팝업 객체
      pPopup500Obj: {},
      pPopup504Obj: {},
      lv_userInfo: this.getStore('userInfo').getters.getUserInfo,
      lv_basInfo: {
        vstTyp: ''
      },
      // 업무구분
      lv_jobTyp: '',
      lv_MsgLogoutConfirm: '앱을 종료하시겠습니까?',

      // 녹취 관련 
      busnScCd: '', //업무구분코드
      recdYmd: '', //녹취일자
      recdStrTm: '', //녹취시작시간
      recdGudeEno: '', //녹취안내자사번
      recdTotPhclTm: '', //녹취총통화시간
      recdFileNm: '', //녹취파일명
      custId: '', //고객ID
      custNm: '', //고객명
      custRrnEncr: '', //고객주민등록번호암호화
      custTelnoEncr: '', //고객전화번호암호화
      nrmTrtYn: 'Y', //정상처리여부
      lstTrtYn: 'N', //최종처리여부
      recdTrtDtm: '', //녹취처리일시
      currDt: '', //녹취시작일시
      endDt: '', //녹취종료일시
      lv_isStartRecord: false, // 녹취여부
      doubleUploadFlag_NAS: false // 업로드 
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {    
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {    
    // 방문 / 내방 선택
    async fn_SelVstType(type) {
      let lv_Vm = this
      let t_type = type === 'vst'? 'O' : 'T' // O(vst): 방문, T(vst): 내방
      let t_msg = type === 'vst'? '방문' : '내방' // O(vst): 방문, T(vst): 내방
      let vstMthRtn = await lv_Vm.$commonUtil.setVstMth(lv_Vm, t_type)
      if(vstMthRtn === '00'){
        lv_Vm.$addSnackbar(t_msg + ' 세션 설정 완료')
      } else {
        lv_Vm.$addSnackbar(t_msg + ' 세션 설정 실패')
      }

      lv_Vm.$bizUtil.tsUtils.selBasInfo(lv_Vm, false).then(function (response) {
        // lv_Vm.$bizUtil.fsUtils.saveJobTyp(lv_Vm, 'ts')
        lv_Vm.$bizUtil.tsUtils.saveBasInfo(lv_Vm, { vstTyp: type })        
        lv_Vm.$bizUtil.tsUtils.saveAgrInfo(lv_Vm)
        if (type === 'vst'){
          lv_Vm.fn_StartRecord() // 레코드 실행
          lv_Vm.fn_OpenVstAgr() // 
        } else {
          lv_Vm.fn_OpenIdcTofCnf()
        }
      }).catch(function (error) {
        window.vue.error(error)
      })      
    },
    /******************************************************************************
     * Function명 : fn_StartRecord
     * 설명       : 레코딩 실행 (방문 > 녹취 시작 동의 시 > 메인)
     ******************************************************************************/
    fn_StartRecord() {
      let auth = 'S'
	    let lv_vm = this
	    let pParams = {}
      window.vue.post(lv_vm, pParams, 'txFSRCD10S1', '')
        .then((response) => {
          try {            
		        console.log("response....................."+JSON.stringify(response));
            let encrKey = response.body.encrKey;
            let encrUUID = response.body.encrUUID;
            console.log("key/uuid..............." + encrKey + ".../..." + encrUUID);
            if(process.env.NODE_ENV === 'local'){
              encrKey = '3447691E0932696B5397B7EA83AD40995C3E5431AA1081166B2AD3110C476650'
              encrUUID = 'b3db2f2c-21ae-4713-b079-dc7bea48ec28'
            }
            if (encrKey == null || encrKey == ""){
              lv_vm.$addSnackbar('암호키 생성에 실패하여 녹취가 정상적으로 시작되지 않았습니다.')              
              lv_vm.lv_isStartRecord = false
              return
            }
            this.fn_startRecodeRslt(encrKey,encrUUID)
            // 로컬테스트
            // if(process.env.NODE_ENV === 'local') {
            //   this.recdTotPhclTm = this.fn_getCalRecTotTime(this.currDt)
            //   this.fn_insRecdFile()
            // }
          } catch (e) {
            window.vue.error(e)  
          }
        })
        .catch((error) => {
          window.vue.error(error)
        })
    },
    /******************************************************************************
     * Function명 : fn_OpenVstAgr
     * 설명       : 방문판매 동의 확인 팝업
     ******************************************************************************/
    fn_OpenVstAgr() {
      this.$refs.vstAgr.open()
    },
    /******************************************************************************
     * Function명 : fn_OpenVstAgr
     * 설명       : 방문판매 동의 확인 종료
     ******************************************************************************/
    fn_SetVstAgr(isAgree) {
      this.$refs.vstAgr.close()
      
      if(this.lv_isStartRecord) {
        // 녹취 종료
        this.fn_endRecord()
      }
      // this.$addSnackbar('레코드 종료')
      // this.$addSnackbar('레코드 전송')
      // this.$addSnackbar('파일 삭제')

      if(isAgree) {
        this.fn_OpenIdcTofCnf()
      } else {
        this.$addSnackbar('앱 종료')
        this.fn_ExitApp()
      }
    },
    /******************************************************************************
     * Function명 : fn_IdcTofCnf
     * 설명       : 신분증 진위확인 팝업 (내방 시 > 신분증 진위확인 > 메인)
     ******************************************************************************/
    fn_OpenIdcTofCnf() {
      this.pPopup500Obj.jobTyp = this.lv_jobTyp
      this.$refs.popup500.fn_OpenModal()
    },
    /******************************************************************************
     * Function명 : fn_OpenCustDocUpload
     * 설명       : 고객서류업로드 팝업 (내방 시 > 신분증 진위확인 > 고객서류업로드 > 메인)
     ******************************************************************************/
    fn_OpenCustDocUpload() {      
      this.$refs.popup504.fn_Open()
    },
    /******************************************************************************
     * Function명 : fn_GoMainPage
     * 설명       : 신탁메인으로 이동
     ******************************************************************************/
    fn_GoMainPage() {
      let t_IsProcess = this.getStore('tsStore').getters.getState.isProcess
      if(t_IsProcess){
        this.$bizUtil.tsUtils.saveProcTyp(this, '')
        this.getStore('tsStore').dispatch('IS_PROCESS_STOP')      
      }
      this.$router.push({
        name: 'MSPTS000M'        
      })
    },
    /******************************************************************************
     * Function명 : fn_Popup_CallBack
     * 설명       : 신분증 진위여부 확인 팝업 결과
     * 타입(type) : 0: 신분증 진위여부
     ******************************************************************************/
    fn_Popup_CallBack(type, pData) {            
      switch (type) {
        case 0: // 신분증진위 팝업
          console.log('신분증 진위여부 callback...')
          this.$refs.popup500.fn_CloseModal()
          if(pData === 'Y') {
            this.fn_OpenCustDocUpload()
          } else if(pData === 'S') { // 고객 서류 Skip 확인 시      
            this.fn_GoMainPage()
          } else {                   
            window.fdpbridge.exec('logoutPlugin', {}, () => {}, () => {})
            console.log('fn_ExitApp - logout !')
            this.$router.push({
              name: 'MSPBC600M'
            })
          }
          break
        case 1: // 고객서류 팝업
          console.log('고객서류등록 callback...')
          this.$refs.popup504.fn_Close()
          if(pData === 'Y') {
            this.fn_GoMainPage()
          }
          break
      }
    },
    /******************************************************************************
    * Function명 : fn_ExitApp
    * 설명       : App 종료 액션
    ******************************************************************************/    
    fn_ExitApp () {
    // console.log('##### fn_ExitApp #####')
    let lv_Vm = this
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: false,
          title: "알림",
          content: this.lv_MsgLogoutConfirm,
          title_pos_btn: "예",
          title_neg_btn: "아니오"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(this.lv_AlertPop)
            window.fdpbridge.exec('logoutPlugin', {}, () => {}, () => {})
            console.log('fn_ExitApp - logout !')
            lv_Vm.$router.push({
              name: 'MSPBC600M'
            })
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_StartRecord
     * 설명       : 레코딩 실행 (방문 > 녹취 시작 동의 시 > 메인)
     ******************************************************************************/
    fn_StartRecord() {      
      let auth = 'S'
	    let lv_vm = this
	    let pParams = {}
      window.vue.post(lv_vm, pParams, 'txFSRCD10S1', '')
        .then((response) => {
          try {            
		        console.log("response....................."+JSON.stringify(response));
            let encrKey = response.body.encrKey;
            let encrUUID = response.body.encrUUID;
            console.log("key/uuid..............." + encrKey + ".../..." + encrUUID);
            if(process.env.NODE_ENV === 'local'){
              encrKey = '3447691E0932696B5397B7EA83AD40995C3E5431AA1081166B2AD3110C476650'
              encrUUID = 'b3db2f2c-21ae-4713-b079-dc7bea48ec28'
            }
            if (encrKey == null || encrKey == ""){
              lv_vm.$addSnackbar('암호키 생성에 실패하여 녹취가 정상적으로 시작되지 않았습니다.')              
              lv_vm.lv_isStartRecord = false
              return
            }
            this.fn_startRecodeRslt(encrKey,encrUUID)
            // 로컬테스트
            // if(process.env.NODE_ENV === 'local') {
            //   this.recdTotPhclTm = this.fn_getCalRecTotTime(this.currDt)
            //   this.fn_insRecdFile()
            // }
          } catch (e) {
            window.vue.error(e)  
          }
        })
        .catch((error) => {
          window.vue.error(error)
        })

    },

    /******************************************************************************
     * Function명 : fn_startRecodeRslt
     * 설명       : 녹취 시작
     ******************************************************************************/
    fn_startRecodeRslt(key, uuid) {      
      this.currDt = PSDateUtil.fn_CurrentDate('yyyyMMddHHmmss')
      this.recdYmd = this.currDt.substr(0,8)
      this.recdStrTm = this.currDt.substr(8,6)
      let userEno = localStorage.getItem('userEno')
      if (userEno == null || userEno == "") userEno = "99999" // 사원번호 없는 경우
      this.recdGudeEno = userEno
      this.recdFileNm = this.currDt + '_' + userEno     // 날짜시분초 + 고객ID(사용자ID)      
      this.busnScCd = '06'

      let lv_vm = this
      window.fdpbridge.exec('RecordStartPlugin', {fileNm:this.recdFileNm, key, uuid}, () => {
        lv_vm.$addSnackbar('방문판매 동의 확인을 위한 녹취를 시작합니다.')
        lv_vm.lv_isStartRecord = true
      } , () => {
        lv_vm.$addSnackbar('녹취 시작 실패했습니다.')
        lv_vm.lv_isStartRecord = false
      })
    },

    /******************************************************************************
    * Function명 : fn_strToDate
    * 설명       : 녹취 시간 
    ******************************************************************************/
    fn_strToDate(sDate){
      let nYear = parseInt(sDate.substr(0, 4));
      let nMonth = parseInt(sDate.substr(4, 2)) - 1;
      let nDate = parseInt(sDate.substr(6, 2));
      
      let nHour = parseInt(sDate.substr(8, 2));
      let nMin = parseInt(sDate.substr(10, 2));
      let nSec = parseInt(sDate.substr(12, 2));
      
      return new Date(nYear, nMonth, nDate, nHour, nMin, nSec);
    },

    /******************************************************************************
    * Function명 : fn_getCalRecTotTime
    * 설명       : 녹취 시간 계산
    ******************************************************************************/
    fn_getCalRecTotTime(dateTime) {
      let currDtOfEnd = PSDateUtil.fn_CurrentDate('yyyyMMddHHmmss')
      let nDiffDate = this.fn_strToDate(currDtOfEnd) - this.fn_strToDate(dateTime)
      return parseInt(nDiffDate / 1000)
    },

    /******************************************************************************
    * Function명 : fn_endRecord
    * 설명       : 녹취 종료 액션
    ******************************************************************************/   
    fn_endRecord() {      
      let lv_vm = this
      window.fdpbridge.exec('RecordStopPlugin', {fileNm: lv_vm.recdFileNm}, () => {
        this.recdTotPhclTm = this.fn_getCalRecTotTime(this.currDt)
        lv_vm.$addSnackbar('녹취가 종료되었습니다.')
        // 파일 전송
        lv_vm.fn_transRecodeFile()
      }, () => {
        lv_vm.lv_isStartRecord = false
        lv_vm.$addSnackbar('녹취가 정상적으로 종료되지 않았습니다.')
      })
    },

    /******************************************************************************
    * Function명 : fn_transRecodeFile
    * 설명       : 녹취 화일 NAS로 전송
    ******************************************************************************/       
    fn_transRecodeFile() {      
      // 녹취파일 업로드 중복처리 방지
      if ( this.doubleUploadFlag_NAS ) {
        this.$addSnackbar("녹취화일을 전송 중 입니다.");
        return
      }
      let lv_vm = this
      let jobPath = "REC_FILE_DIR_TS"      
      let t_Param = {
        serverurl:jobPath,
        fileNm: this.recdFileNm, // 저장파일명
      }
      // this.fn_trace('info', '녹취파일 업로드 시작합니다.', t_Param)
      // this.fn_ShowLoading(true, '2') // 화면 로딩 처리 // 화면 로딩
      this.doubleUploadFlag_NAS = true      
      window.fdpbridge.exec('RecordUploadPlugin', t_Param, function (result) {
        console.log('RecordUploadPlugin::result::::', JSON.stringify(result))
        lv_vm.doubleUploadFlag_NAS = false
        if (result.data === '200' || result.data === 'ok') {
          lv_vm.fn_insRecdFile () //녹취파일 저장
        } else {
          lv_vm.fn_insRecdFile (1) //녹취파일 오류          
        } // end else if
      }, function (result) {
          lv_vm.fn_insRecdFile (2) //녹취파일 오류        
      })
    },

    /******************************************************************************
    * Function명 : fn_insRecdFile
    * 설명       : 녹취 화일 저장 오류 처리
    ******************************************************************************/       
    async fn_insRecdFile (err) {
      let nrmTrtYn = ''
      if (this.$bizUtil.isEmpty(err)) {
        if(process.env.NODE_ENV !== 'local'){
          await this.$commonUtil.delRecData(this, this.recdFileNm)
        }
        nrmTrtYn = 'Y'
      } else {
        nrmTrtYn = 'N'
      }

      this.recdTrtDtm = PSDateUtil.fn_CurrentDate('yyyyMMddHHmmss')
      let tmpCustData = {
        custId: '9999900000',
        custNm: '비추미',
        rrnAesEncr: '9999990000000',
        custTelnoEncr: '01099990000',
      }
      let rrnAesEncr = ''
      let custTelnoEncr = ''
      if(process.env.NODE_ENV !== 'local'){
        try {
          rrnAesEncr = await this.$commonUtil.getEncriptData(this, tmpCustData.rrnAesEncr)
          custTelnoEncr = await this.$commonUtil.getEncriptData(this, tmpCustData.custTelnoEncr)
          rrnAesEncr = rrnAesEncr.trim().replace(/\n/g, '')
          custTelnoEncr = custTelnoEncr.trim().replace(/\n/g, '')
        } catch (e) {
          window.vue.error(e)  
        }        
      } else {
        rrnAesEncr = tmpCustData.rrnAesEncr
        custTelnoEncr = tmpCustData.custTelnoEncr
      }

	    let lv_vm = this
      let pParams = {
        busnScCd: this.busnScCd,
        recdYmd: this.recdYmd,
        recdStrTm: this.recdStrTm,
        recdGudeEno: this.recdGudeEno,
        recdTotPhclTm: this.recdTotPhclTm,
        recdFileNm: this.recdFileNm + '.mp3.crypt',
        custId: tmpCustData.custId,
        custNm: tmpCustData.custNm,
        rrnAesEncr: rrnAesEncr,
        custTelnoEncr: custTelnoEncr,
        nrmTrtYn: nrmTrtYn,
        lstTrtYn: this.lstTrtYn,
        recdTrtDtm: this.recdTrtDtm
      }

      // alert('fn_insRecdFile - param : ' + JSON.stringify(pParams))
      console.log("fn_insRecdFile........................" + JSON.stringify(pParams))
      window.vue.post(lv_vm, pParams, 'txTSSBC99I1', '')
        .then((response) => {
          try {
            // alert('fn_insRecdFile - response : ' + JSON.stringify(response))
		        console.log("insert response....................."+JSON.stringify(response))
          } catch (e) {
            window.vue.error(e)  
          } 
        })
        .catch((error) => {
          window.vue.error(error)
        })
    },    
    
    /************************************************************************************************
     * Function명  : fn_ShowLoading
     * 설명        : 화면 로딩 처리 공통 메소드
     ************************************************************************************************/
    fn_ShowLoading: function (isShow, showType = ' ') {
      console.log('this.fn_ShowLoading Call', 'showType : ' + showType)
      this.isShowLoading = isShow // 화면 로딩
      if (showType === '0') {
        this.isShowLoading1 = false // 진행 중
        this.isShowLoading2 = false // 처리 중
      } else if (showType === '1') {
        this.isShowLoading1 = true // 진행 중
        this.isShowLoading2 = false // 처리 중
      } else if (showType === '2') {
        this.isShowLoading1 = false // 진행 중
        this.isShowLoading2 = true // 처리 중
      } // end else if
    },

    /************************************************************************************************
     * Function명  : fn_trace
     * 설명        : 프로그램 흐름 및 에러 로그 추적 공통 함수
     ************************************************************************************************/
    fn_trace: function (type, addMsg, outData, msgType = ' ', fromScreenId) {
      alert(addMsg);
    },
  }
};
</script>
<style scoped>
</style>